import { DisplayFromFHIR, getStringFromFHIR } from './ResourceFunctions';
import { Table } from 'semantic-ui-react';
import EvidenceVariableCriteriaDisplay from './EvidenceVariableCriteriaDisplay';
import DisplayGroupCharacteristics from './DisplayGroupCharacteristics';
import ResourceBottomDisplay from './ResourceBottomDisplay';

const EvidenceVariableDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatedArtifact)) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }
  let definitionReference = fhirJson.definition?.reference?.reference || "";
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.shortTitle && <p><b>Short title: </b>{fhirJson.shortTitle}</p>}
        {fhirJson.name && <p><b>Name: </b>{fhirJson.name}</p>}
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
        {Array.isArray(fhirJson.note) &&
          fhirJson.note.map((note, noteIndex) => {
            return <div key={noteIndex}><b>Note: </b><DisplayFromFHIR annotation={note} /><br /><br /></div>
          })}
        {(typeof fhirJson.actual === "boolean") &&
          <p><b>Actual:</b> {getStringFromFHIR.boolean(fhirJson.actual)}</p>}
        {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") && <>
          <h4>Narrative Summary:</h4>
          <div style={{ marginLeft: "24px" }}>
            <DisplayFromFHIR xhtml={fhirJson.text.div} />
          </div>
        </>}
      </div>
      <h3 id="definition">Definition</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.definition && <>
          <DisplayFromFHIR codeableReference={fhirJson.definition} />
          {definitionReference && <>
            <br />
            <DisplayGroupCharacteristics reference={definitionReference} />
          </>}
        </>}
        {Array.isArray(fhirJson.definitionModifier) &&
          fhirJson.definitionModifier.map((modifier, modifierIndex) => {
            return <div key={modifierIndex}>
              <h4>Definition Modifier</h4>
              <DisplayFromFHIR codeableConcept={modifier.code} />
              <span> matching </span>
              {modifier.valueCodeableConcept && <DisplayFromFHIR codeableConcept={modifier.valueCodeableConcept} />}
              {modifier.valueQuantity && getStringFromFHIR.Quantity(modifier.valueQuantity)}
              {modifier.valueRange && getStringFromFHIR.Range(modifier.valueRange)}
              {modifier.valuePeriod && getStringFromFHIR.Period(modifier.valuePeriod)}
              {modifier.valueRelativeTime && getStringFromFHIR.RelativeTime(modifier.valueRelativeTime)}
              {modifier.valueReference && <DisplayFromFHIR reference={modifier.valueReference} />}
              {modifier.valueExpression && <DisplayFromFHIR expression={modifier.valueExpression} />}
              {modifier.valueUri && <DisplayFromFHIR uri={modifier.valueUri} />}
              {modifier.valueBoolean === true && <span>TRUE</span>}
              {modifier.valueBoolean === false && <span>FALSE</span>}
            </div>
          })}
        {fhirJson.characteristicDefinition &&
          <DisplayFromFHIR reference={fhirJson.characteristicDefinition} />}
        {fhirJson.characteristic && <EvidenceVariableCriteriaDisplay fhirJson={fhirJson} />}
        {(!fhirJson.definition && !fhirJson.characteristic && !fhirJson.characteristicDefinition) &&
          <p>No definition element (No structured definition.)</p>}
      </div>
      <h3 id="data-handling">Data Handling</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.handling
          ?
          <p><b>Handling: </b>{fhirJson.handling}</p>
          :
          <p><b>Handling: </b>No handling data (No classification of continuous, dichotomous, ordinal, or polychotomous).</p>
        }
        {(Array.isArray(fhirJson.category) && fhirJson.category.length > 0) &&
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Value</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {fhirJson.category.map((category, categoryIndex) => {
                return <Table.Row key={categoryIndex}>
                  <Table.Cell style={{ verticalAlign: "top", width: "25%" }}>
                    {(category.name && typeof category.name === "string") && category.name}
                  </Table.Cell>
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    {category.valueCodeableConcept && <DisplayFromFHIR codeableConcept={category.valueCodeableConcept} />}
                    {category.valueQuantity && getStringFromFHIR.Quantity(category.valueQuantity)}
                    {category.valueRange && getStringFromFHIR.Range(category.valueRange)}
                    {category.valueReference && <DisplayFromFHIR reference={category.valueReference} />}
                  </Table.Cell>
                </Table.Row>
              })}
            </Table.Body>
          </Table>
        }
        {fhirJson.conditional &&
          <span>
            <b>Conditional (measured if): </b>
            <DisplayFromFHIR expression={fhirJson.conditional} />
            <br />
          </span>
        }
        {Array.isArray(fhirJson.classifier) &&
          fhirJson.classifier.map((classifier, itemIndex) => {
            return <div key={itemIndex}><b>Classifier: </b><DisplayFromFHIR codeableConcept={classifier} /><br /><br /></div>
          })
        }
        {Array.isArray(fhirJson.dataStorage) &&
          fhirJson.dataStorage.map((dataStorage, itemIndex) => {
            return <div key={itemIndex}>
              <h4>Data storage</h4>
              <div style={{ marginLeft: "24px" }}>
                {dataStorage.datatype &&
                  <span>
                    <b>Datatype: </b>
                    <DisplayFromFHIR codeableConcept={dataStorage.datatype} />
                  </span>
                }
                {dataStorage.path &&
                  <p><b>Path: </b>{dataStorage.path}</p>
                }
                {Array.isArray(dataStorage.component) &&
                  dataStorage.component.map((part, partIndex) => {
                    return <div key={partIndex}>
                      <h5>Component</h5>
                      <div style={{ marginLeft: "24px" }}>
                        {part.datatype &&
                          <span>
                            <b>Datatype: </b>
                            <DisplayFromFHIR codeableConcept={part.datatype} />
                          </span>
                        }
                        {part.path &&
                          <p><b>Path: </b>{part.path}</p>
                        }
                        {(Array.isArray(part.component) && part.component.length) &&
                          <p>has components</p>}
                      </div>
                    </div>
                  })}
              </div>
              <br />
            </div>
          })
        }
        {fhirJson.timing &&
          <p><b>Timing: </b>{getStringFromFHIR.RelativeTime(fhirJson.timing)}</p>
        }
        {fhirJson.period &&
          <p><b>Timing (Dates): </b>{getStringFromFHIR.Period(fhirJson.period)}</p>
        }
        {Array.isArray(fhirJson.constraint) &&
          fhirJson.constraint.map((constraint, itemIndex) => {
            return <div key={itemIndex}>
              <h4>Constraint</h4>
              <div style={{ marginLeft: "24px" }}>
                {constraint.conditional &&
                  <span>
                    <b>Conditional (constraint applies if): </b>
                    <DisplayFromFHIR codeableConcept={constraint.conditional} />
                    <br /> <br />
                  </span>
                }
                {constraint.minimumQuantity &&
                  <p><b>Minimum quantity: </b>{getStringFromFHIR.Quantity(constraint.minimumQuantity)}</p>
                }
                {constraint.maximumQuantity &&
                  <p><b>Maximum quantity: </b>{getStringFromFHIR.Quantity(constraint.maximumQuantity)}</p>
                }
                {constraint.earliestDateTime &&
                  <p><b>Earliest time: </b>{getStringFromFHIR.dateTime(constraint.earliestDateTime)}</p>
                }
                {constraint.latestDateTime &&
                  <p><b>Latest time: </b>{getStringFromFHIR.dateTime(constraint.latestDateTime)}</p>
                }
                {(constraint.minimumStringLength || constraint.minimumStringLength === 0) &&
                  <p><b>Minimum string length: </b>{constraint.minimumStringLength.toString()} characters</p>
                }
                {(constraint.maximumStringLength) &&
                  <p><b>Maximum string length: </b>{constraint.maximumStringLength.toString()} characters</p>
                }
                {constraint.code &&
                  <span>
                    <b>Rule: </b>
                    <DisplayFromFHIR codeableConcept={constraint.code} />
                    <br /> <br />
                  </span>
                }
                {constraint.expression &&
                  <span>
                    <b>Rule: </b>
                    <DisplayFromFHIR expression={constraint.expression} />
                    <br />
                  </span>
                }
                {constraint.expectedValueSet &&
                  <span>
                    <b>Expected values: </b>
                    <DisplayFromFHIR reference={constraint.expectedValueSet} />
                    <br /><br />
                  </span>
                }
                {constraint.expectedUnitsValueSet &&
                  <span>
                    <b>Expected units: </b>
                    <DisplayFromFHIR reference={constraint.expectedUnitsValueSet} />
                    <br /><br />
                  </span>
                }
                {constraint.anyValueAllowed === true &&
                  <p><b>Any value allowed: </b>TRUE</p>
                }
                {constraint.anyValueAllowed === false &&
                  <p><b>Any value allowed: </b>FALSE</p>
                }
              </div>
              <br />
            </div>
          })
        }
        {fhirJson.missingDataMeaning &&
          <span>
            <b>Missing data: </b>
            <DisplayFromFHIR codeableConcept={fhirJson.missingDataMeaning} />
            <br /><br />
          </span>
        }
        {Array.isArray(fhirJson.unacceptableDataHandling) &&
          fhirJson.unacceptableDataHandling.map((unacceptableDataHandling, itemIndex) => {
            return <div key={itemIndex}><b>Unacceptable data handling: </b><DisplayFromFHIR codeableConcept={unacceptableDataHandling} /><br /><br /></div>
          })
        }
      </div>
      <br />
      <ResourceBottomDisplay howToCite={howToCite} fhirJson={fhirJson}
        classificationsLoadedState={classificationsLoadedState} classificationsArray={classificationsArrayState}
      />
    </div>
  </div >
}

export default EvidenceVariableDisplay;