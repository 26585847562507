import React from 'react';
import { Table } from 'semantic-ui-react';
import { DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { EvidenceReportPackageSectionDisplay } from './EvidenceReportPackageViewingTool';

const EvidenceTableDisplay = ({ resultsSections, reportType }) => {
  let screenedEvidenceFound;
  let enrolledEvidenceFound;
  let analyzedEvidenceFound;
  let interventionEvidenceFound;
  let comparatorEvidenceFound;
  let comparativeEvidenceFound;
  let populationGroupFound;
  let interventionGroupFound;
  let comparatorGroupFound;
  for (const section of resultsSections) {
    if (section?.section?.length) {
      for (const subsection of section.section) {
        let sectionCode = subsection?.code?.coding?.[0]?.code;
        if (subsection?.focus) {
          switch (sectionCode) {
            case 'evidence-for-screened-group':
              screenedEvidenceFound = subsection.focus?.display || true;
              break;
            case 'evidence-for-enrolled-group':
              enrolledEvidenceFound = subsection.focus?.display || true;
              break;
            case 'evidence-for-analyzed-group':
              analyzedEvidenceFound = subsection.focus?.display || true;
              break;
            case 'evidence-with-intervention-alone':
              interventionEvidenceFound = subsection.focus?.display || true;
              break;
            case 'evidence-with-comparator-alone':
              comparatorEvidenceFound = subsection.focus?.display || true;
              break;
            case 'comparative-results':
              comparativeEvidenceFound = subsection.focus?.display || true;
              break;
            case 'population':
              populationGroupFound = subsection.focus?.display || true;
              break;
            case 'intervention-group':
              interventionGroupFound = subsection.focus?.display || true;
              break;
            case 'comparator-group':
              comparatorGroupFound = subsection.focus?.display || true;
              break;
            default:
            //
          }
        }
      }
    }
  }

  return <>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable Name</Table.HeaderCell>
          {populationGroupFound &&
            <Table.HeaderCell>Population</Table.HeaderCell>}
          {interventionGroupFound &&
            <Table.HeaderCell>Intervention Group</Table.HeaderCell>}
          {comparatorGroupFound &&
            <Table.HeaderCell>Comparator Group</Table.HeaderCell>}
          {screenedEvidenceFound &&
            <Table.HeaderCell>Screened Group</Table.HeaderCell>}
          {enrolledEvidenceFound &&
            <Table.HeaderCell>Enrolled Group</Table.HeaderCell>}
          {analyzedEvidenceFound &&
            <Table.HeaderCell>Analyzed Group</Table.HeaderCell>}
          {interventionEvidenceFound &&
            <Table.HeaderCell>With Intervention</Table.HeaderCell>}
          {comparatorEvidenceFound &&
            <Table.HeaderCell>With Comparator</Table.HeaderCell>}
          {comparativeEvidenceFound &&
            <Table.HeaderCell>Comparative Evidence</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row key={"topline"}>
          <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
          {populationGroupFound &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {typeof populationGroupFound === "string" && populationGroupFound}
            </Table.Cell>}
          {interventionGroupFound &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {typeof interventionGroupFound === "string" && interventionGroupFound}
            </Table.Cell>}
          {comparatorGroupFound &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {typeof comparatorGroupFound === "string" && comparatorGroupFound}
            </Table.Cell>}
          {screenedEvidenceFound &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {typeof screenedEvidenceFound === "string" && screenedEvidenceFound}
            </Table.Cell>}
          {enrolledEvidenceFound &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {typeof enrolledEvidenceFound === "string" && enrolledEvidenceFound}
            </Table.Cell>}
          {analyzedEvidenceFound &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {typeof analyzedEvidenceFound === "string" && analyzedEvidenceFound}
            </Table.Cell>}
          {interventionEvidenceFound &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {typeof interventionEvidenceFound === "string" && interventionEvidenceFound}
            </Table.Cell>}
          {comparatorEvidenceFound &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {typeof comparatorEvidenceFound === "string" && comparatorEvidenceFound}
            </Table.Cell>}
          {comparativeEvidenceFound &&
            <Table.Cell style={{ verticalAlign: "top" }}>
              {typeof comparativeEvidenceFound === "string" && comparativeEvidenceFound}
            </Table.Cell>}
        </Table.Row>
        {resultsSections.map((itemSection, index) => {
          let populationSection = null;
          let interventionGroupSection = null;
          let comparatorGroupSection = null;
          let screenedEvidenceSection = null;
          let enrolledEvidenceSection = null;
          let analyzedEvidenceSection = null;
          let interventionEvidenceSection = null;
          let comparatorEvidenceSection = null;
          let comparativeEvidenceSection = null;
          if (Array.isArray(itemSection.section)) {
            for (let subsection of itemSection.section) {
              let subsectionCode = subsection.code?.coding?.[0]?.code;
              if (subsectionCode === "evidence-with-comparator-alone") {
                comparatorEvidenceSection = subsection;
              } else if (subsectionCode === "evidence-with-intervention-alone") {
                interventionEvidenceSection = subsection;
              } else if (subsectionCode === "evidence-for-analyzed-group") {
                analyzedEvidenceSection = subsection;
              } else if (subsectionCode === "comparative-results" || subsectionCode === "evidence-with-intervention-vs-comparator") {
                comparativeEvidenceSection = subsection;
              } else if (subsectionCode === "population") {
                populationSection = subsection;
              } else if (subsectionCode === "evidence-for-enrolled-group") {
                enrolledEvidenceSection = subsection;
              } else if (subsectionCode === "evidence-for-screened-group") {
                screenedEvidenceSection = subsection;
              } else if (subsectionCode === "intervention-group") {
                interventionGroupSection = subsection;
              } else if (subsectionCode === "comparator-group") {
                comparatorGroupSection = subsection;
              }
            }
            return <Table.Row key={index}>
              <Table.Cell style={{ verticalAlign: "top" }}>{itemSection.title || itemSection.focus?.display}</Table.Cell>
              {populationGroupFound &&
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {populationSection && <span>
                    <DisplayFromFHIR xhtml={populationSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={populationSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
              {interventionGroupFound &&
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {interventionGroupSection && <span>
                    <DisplayFromFHIR xhtml={interventionGroupSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={interventionGroupSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
              {comparatorGroupFound &&
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {comparatorGroupSection && <span>
                    <DisplayFromFHIR xhtml={comparatorGroupSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={comparatorGroupSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
              {screenedEvidenceFound &&
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {screenedEvidenceSection && <span>
                    <DisplayFromFHIR xhtml={screenedEvidenceSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={screenedEvidenceSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
              {enrolledEvidenceFound &&
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {enrolledEvidenceSection && <span>
                    <DisplayFromFHIR xhtml={enrolledEvidenceSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={enrolledEvidenceSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
              {analyzedEvidenceFound &&
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {analyzedEvidenceSection && <span>
                    <DisplayFromFHIR xhtml={analyzedEvidenceSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={analyzedEvidenceSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
              {interventionEvidenceFound &&
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {interventionEvidenceSection && <span>
                    <DisplayFromFHIR xhtml={interventionEvidenceSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={interventionEvidenceSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
              {comparatorEvidenceFound &&
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {comparatorEvidenceSection && <span>
                    <DisplayFromFHIR xhtml={comparatorEvidenceSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={comparatorEvidenceSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
              {comparativeEvidenceFound &&
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {comparativeEvidenceSection && <span>
                    <DisplayFromFHIR xhtml={comparativeEvidenceSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={comparativeEvidenceSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
            </Table.Row>
          } else {
            return <></>
          }
        })}
      </Table.Body>
    </Table>
  </>
}

const EvidenceTableReportPackageViewerTool = ({ fhirJson, classificationsArrayState,
  classificationsLoadedState, reportType }) => {

  let description;
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-description') {
        description = extension.valueMarkdown;
      }
    }
  }
  let howToCite;
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.resourceReference?.display;
      }
    }
  }
  let resultsSections = [];
  let additionalSections = [];
  if (Array.isArray(fhirJson.section) && fhirJson.section.length > 0) {
    for (const section of fhirJson.section) {
      if (section?.code?.coding?.[0]?.code === "results") {
        resultsSections.push(section);
      } else {
        additionalSections.push(section);
      }
    }
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      {description &&
        <div><b>Report Description: </b><DisplayFromFHIR markdown={description} />
        </div>}
      <h3 id="evidence">Evidence</h3>
      <EvidenceTableDisplay resultsSections={resultsSections} reportType={reportType} />
      <h3 id="discussion">Discussion</h3>
      {additionalSections.length ? <>
        {additionalSections.map((section, sectionIndex) => {
          return <div key={sectionIndex}>
            <EvidenceReportPackageSectionDisplay topSection={section} />
          </div>
        })}
      </>
        :
        <p>[No data other than the Evidence Table.]</p>}
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.category) && fhirJson.category.length > 0 && <>
          {fhirJson.category.map((category, categoryIndex) => {
            return <span key={categoryIndex} >
              <b>Category: </b>
              <DisplayFromFHIR codeableConcept={category} />
            </span>
          })}
          <br /> <br />
        </>}
        {Array.isArray(fhirJson.subject) && fhirJson.subject.length > 0 && <>
          {fhirJson.subject.map((subject, subjectIndex) => {
            return <span key={subjectIndex} >
              <b>Subject: </b>
              <DisplayFromFHIR reference={subject} />
            </span>
          })}
          <br /> <br />
        </>}
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default EvidenceTableReportPackageViewerTool;