import React, { useContext } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import { useHistory } from "react-router-dom";
import FevirContext from './FevirContext';
import { createSummaryOfFindingsReport } from './SummaryOfFindingsFunctions';
import { EvidenceReportPackageSectionDisplay } from './EvidenceReportPackageViewingTool';

const EvidenceTableDisplay = ({ relatesTo, section, reportType }) => {
  let usableSection;
  if (section?.[2]?.code?.coding?.[0]?.code === "results" && section[2].section?.length) {
    usableSection = section[2].section;
    return <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Variable Name</Table.HeaderCell>
            {section[0].section[0]?.entry?.length && <Table.HeaderCell>Total Group</Table.HeaderCell>}
            {section[0].section[1]?.entry?.length && <Table.HeaderCell>Intervention Group</Table.HeaderCell>}
            {section[0].section[2]?.entry?.length && <Table.HeaderCell>Comparator Group</Table.HeaderCell>}
            {section[0].section[3]?.entry?.length && <Table.HeaderCell>Comparative Evidence</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={"topline"}>
            <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
            {section[0].section[0]?.entry?.length && <Table.Cell style={{ verticalAlign: "top" }}>{section[0].section[0].entry[0].display || section[0].section[0].entry[0].reference || "[Display value missing.]"}</Table.Cell>}
            {section[0].section[1]?.entry?.length && <Table.Cell style={{ verticalAlign: "top" }}>{section[0].section[1].entry[0].display || section[0].section[1].entry[0].reference || "[Display value missing.]"}</Table.Cell>}
            {section[0].section[2]?.entry?.length && <Table.Cell style={{ verticalAlign: "top" }}>{section[0].section[2].entry[0].display || section[0].section[2].entry[0].reference || "[Display value missing.]"}</Table.Cell>}
            {section[0].section[3]?.entry?.length && <Table.Cell style={{ verticalAlign: "top" }}>{section[0].section[3].entry[0].display || section[0].section[3].entry[0].reference || "[Display value missing.]"}</Table.Cell>}
          </Table.Row>
          {usableSection.map((itemSection, index) => {
            let comparatorSection = null;
            let interventionSection = null;
            let totalSection = null;
            let comparativeSection = null;
            if (Array.isArray(itemSection.section)) {
              for (let subsection of itemSection.section) {
                let subsectionCode = subsection.code?.coding?.[0]?.code;
                if (subsectionCode === "evidence-with-comparator-alone") {
                  comparatorSection = subsection;
                } else if (subsectionCode === "evidence-with-intervention-alone") {
                  interventionSection = subsection;
                } else if (subsectionCode === "evidence-with-total-group") {
                  totalSection = subsection;
                } else if (subsectionCode === "evidence-with-intervention-vs-comparator") {
                  comparativeSection = subsection;
                }
              }
              return <Table.Row key={index}>
                <Table.Cell style={{ verticalAlign: "top" }}>{itemSection.title || itemSection.focus?.display}</Table.Cell>
                {section[0].section[0]?.entry?.length && <Table.Cell style={{ verticalAlign: "top" }}>
                  {totalSection && <span>
                    <DisplayFromFHIR xhtml={totalSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={totalSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
                {section[0].section[1]?.entry?.length && <Table.Cell style={{ verticalAlign: "top" }}>
                  {interventionSection && <span>
                    <DisplayFromFHIR xhtml={interventionSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={interventionSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
                {section[0].section[2]?.entry?.length && <Table.Cell style={{ verticalAlign: "top" }}>
                  {comparatorSection && <span>
                    <DisplayFromFHIR xhtml={comparatorSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={comparatorSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
                {section[0].section[3]?.entry?.length && <Table.Cell style={{ verticalAlign: "top" }}>
                  {comparativeSection && <span>
                    <DisplayFromFHIR xhtml={comparativeSection.text?.div} />
                    <br />
                    <DisplayFromFHIR reference={comparativeSection.entry?.[0]} />
                  </span>}
                </Table.Cell>}
              </Table.Row>
            } else {
              return <></>
            }
          })}
        </Table.Body>
      </Table>
    </>
  }

  let interventionGroupReference = null;
  let interventionGroupDisplay = "";
  let comparatorGroupReference = null;
  let comparatorGroupDisplay = "";
  let totalGroupReference = null;
  let totalGroupDisplay = "";
  let groupAssignmentReference = null;
  let groupAssignmentDisplay = "";
  let variableReferencesList = [];
  if (relatesTo) {
    for (let item of relatesTo) {
      if (item.type === "depends-on") {
        if (item.label === "Intervention Group") {
          interventionGroupReference = item.resourceReference?.reference;
          interventionGroupDisplay = item.display || item.resourceReference?.display || "";
        } else if (item.label === "Comparator Group") {
          comparatorGroupReference = item.resourceReference?.reference;
          comparatorGroupDisplay = item.display || item.resourceReference?.display || "";
        } else if (item.label === "Total Group") {
          totalGroupReference = item.resourceReference?.reference;
          totalGroupDisplay = item.display || item.resourceReference?.display || "";
        } else if (item.label === reportType) {
          let variableReferenceDisplay = item.display || item.resourceReference?.display;
          if (variableReferenceDisplay) {
            variableReferencesList.push(variableReferenceDisplay);
          }
        }
      } else if (item.type === "composed-of") {
        if (item.label === "Group Assignment" || item.classifier?.[0]?.coding?.[0]?.code === "GroupAssignment") {
          groupAssignmentReference = item.resourceReference?.reference;
          groupAssignmentDisplay = item.display || item.resourceReference?.display || "";
        }
      }
    }
  }
  usableSection = section.filter((row) => {
    let rowFocus = row.title || row.focus?.display;
    let match = false;
    if (rowFocus && variableReferencesList.includes(rowFocus)) {
      match = true;
    }
    return match;
  });

  return <>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable Name</Table.HeaderCell>
          {totalGroupReference && <Table.HeaderCell>Total Group</Table.HeaderCell>}
          {interventionGroupReference && <Table.HeaderCell>Intervention Group</Table.HeaderCell>}
          {comparatorGroupReference && <Table.HeaderCell>Comparator Group</Table.HeaderCell>}
          {groupAssignmentReference && <Table.HeaderCell>Comparative Evidence</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row key={"topline"}>
          <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
          {totalGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>{totalGroupDisplay || totalGroupReference}</Table.Cell>}
          {interventionGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>{interventionGroupDisplay || interventionGroupReference}</Table.Cell>}
          {comparatorGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>{comparatorGroupDisplay || comparatorGroupReference}</Table.Cell>}
          {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>{groupAssignmentDisplay || groupAssignmentReference}</Table.Cell>}
        </Table.Row>
        {usableSection.map((section, index) => {
          let comparatorSection = null;
          let interventionSection = null;
          let totalSection = null;
          let comparativeSection = null;
          for (let subsection of section.section) {
            let subsectionCode = subsection.code?.coding?.[0]?.code;
            if (subsectionCode === "evidence-with-comparator-alone") {
              comparatorSection = subsection;
            } else if (subsectionCode === "evidence-with-intervention-alone") {
              interventionSection = subsection;
            } else if (subsectionCode === "evidence-with-total-group") {
              totalSection = subsection;
            } else if (subsectionCode === "evidence-with-intervention-vs-comparator") {
              comparativeSection = subsection;
            }
          }
          return <Table.Row key={index}>
            <Table.Cell style={{ verticalAlign: "top" }}>{section.title || section.focus?.display}</Table.Cell>
            {totalGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {totalSection && <span>
                <DisplayFromFHIR xhtml={totalSection.text?.div} />
                <br />
                <DisplayFromFHIR reference={totalSection.entry?.[0]} />
              </span>}
            </Table.Cell>}
            {interventionGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {interventionSection && <span>
                <DisplayFromFHIR xhtml={interventionSection.text?.div} />
                <br />
                <DisplayFromFHIR reference={interventionSection.entry?.[0]} />
              </span>}
            </Table.Cell>}
            {comparatorGroupReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {comparatorSection && <span>
                <DisplayFromFHIR xhtml={comparatorSection.text?.div} />
                <br />
                <DisplayFromFHIR reference={comparatorSection.entry?.[0]} />
              </span>}
            </Table.Cell>}
            {groupAssignmentReference && <Table.Cell style={{ verticalAlign: "top" }}>
              {comparativeSection && <span>
                <DisplayFromFHIR xhtml={comparativeSection.text?.div} />
                <br />
                <DisplayFromFHIR reference={comparativeSection.entry?.[0]} />
              </span>}
            </Table.Cell>}
          </Table.Row>
        })}
      </Table.Body>
    </Table>
  </>
}

const EvidenceTableDisplay2 = ({ section, resultsType }) => {
  let usableSection;
  let screenedGroupFound;
  let enrolledGroupFound;
  let analyzedGroupFound;
  let populationDescriptionFound;
  let totalGroupFound; //old name for what is now analyzedGroup (or populationDescription)
  let interventionGroupFound;
  let comparatorGroupFound;
  let groupAssignmentFound;
  if (section[0].section[0]?.entry?.length) {
    screenedGroupFound = true;
  }
  if (section[0].section[1]?.entry?.length) {
    enrolledGroupFound = true;
  }
  if (section[0].section[7]?.entry?.length) {
    analyzedGroupFound = true;
  }
  if (section[0].section[2]?.entry?.length) {
    populationDescriptionFound = true;
  }
  if (analyzedGroupFound || populationDescriptionFound) {
    totalGroupFound = true;
  }
  if (section[0].section[3]?.entry?.length || section[0].section[5]?.entry?.length) {
    interventionGroupFound = true;
  }
  if (section[0].section[4]?.entry?.length || section[0].section[6]?.entry?.length) {
    comparatorGroupFound = true;
  }
  if (section[1]?.entry?.length) {
    groupAssignmentFound = true;
  }
  if (resultsType === "comparative-results" && section?.[3]?.code?.coding?.[0]?.code === "results" &&
    section[3].section?.[1]?.code?.coding?.[0]?.code === "comparative-results" &&
    section[3].section[1].section?.length) {
    usableSection = section[3].section[1].section;
    return <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Variable Name</Table.HeaderCell>
            {totalGroupFound &&
              <Table.HeaderCell>Total Group</Table.HeaderCell>}
            {interventionGroupFound &&
              <Table.HeaderCell>Intervention Group</Table.HeaderCell>}
            {comparatorGroupFound &&
              <Table.HeaderCell>Comparator Group</Table.HeaderCell>}
            {groupAssignmentFound && <Table.HeaderCell>Comparative Evidence</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={"topline"}>
            <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
            {totalGroupFound &&
              <Table.Cell style={{ verticalAlign: "top" }}>
                {section[0].section[7].entry?.[0]?.display || section[0].section[7].entry?.[0]?.reference ||
                  section[0].section[2].entry?.[0]?.display || section[0].section[2].entry?.[0]?.reference ||
                  "[Display value missing.]"}
              </Table.Cell>}
            {interventionGroupFound &&
              <Table.Cell style={{ verticalAlign: "top" }}>
                {section[0].section[3].entry?.[0]?.display || section[0].section[3].entry?.[0]?.reference ||
                  section[0].section[5].entry?.[0]?.display || section[0].section[5].entry?.[0]?.reference ||
                  "[Display value missing.]"}
              </Table.Cell>}
            {comparatorGroupFound &&
              <Table.Cell style={{ verticalAlign: "top" }}>
                {section[0].section[4].entry?.[0]?.display || section[0].section[4].entry?.[0]?.reference ||
                  section[0].section[6].entry?.[0]?.display || section[0].section[6].entry?.[0]?.reference ||
                  "[Display value missing.]"}
              </Table.Cell>}
            {groupAssignmentFound &&
              <Table.Cell style={{ verticalAlign: "top" }}>
                {section[1].entry[0].display || section[1].entry[0].reference || "[Display value missing.]"}
              </Table.Cell>}
          </Table.Row>
          {usableSection.map((itemSection, index) => {
            let comparatorSection = null;
            let interventionSection = null;
            let totalSection = null;
            let comparativeSection = null;
            if (Array.isArray(itemSection.section)) {
              for (let subsection of itemSection.section) {
                let subsectionCode = subsection.code?.coding?.[0]?.code;
                if (subsectionCode === "evidence-with-comparator-alone") {
                  comparatorSection = subsection;
                } else if (subsectionCode === "evidence-with-intervention-alone") {
                  interventionSection = subsection;
                } else if (subsectionCode === "evidence-for-analyzed-group" || subsectionCode === "evidence-with-total-group") {
                  totalSection = subsection;
                } else if (subsectionCode === "evidence-with-intervention-vs-comparator") {
                  comparativeSection = subsection;
                }
              }
              return <Table.Row key={index}>
                <Table.Cell style={{ verticalAlign: "top" }}>{itemSection.title || itemSection.focus?.display}</Table.Cell>
                {totalGroupFound &&
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    {totalSection && <span>
                      <DisplayFromFHIR xhtml={totalSection.text?.div} />
                      <br />
                      <DisplayFromFHIR reference={totalSection.entry?.[0]} />
                    </span>}
                  </Table.Cell>}
                {interventionGroupFound &&
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    {interventionSection && <span>
                      <DisplayFromFHIR xhtml={interventionSection.text?.div} />
                      <br />
                      <DisplayFromFHIR reference={interventionSection.entry?.[0]} />
                    </span>}
                  </Table.Cell>}
                {comparatorGroupFound &&
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    {comparatorSection && <span>
                      <DisplayFromFHIR xhtml={comparatorSection.text?.div} />
                      <br />
                      <DisplayFromFHIR reference={comparatorSection.entry?.[0]} />
                    </span>}
                  </Table.Cell>}
                {groupAssignmentFound &&
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    {comparativeSection && <span>
                      <DisplayFromFHIR xhtml={comparativeSection.text?.div} />
                      <br />
                      <DisplayFromFHIR reference={comparativeSection.entry?.[0]} />
                    </span>}
                  </Table.Cell>}
              </Table.Row>
            } else {
              return <></>
            }
          })}
        </Table.Body>
      </Table>
    </>
  }
  if (resultsType === "enrollment-results" && section?.[3]?.code?.coding?.[0]?.code === "results" &&
    section[3].section?.[0]?.code?.coding?.[0]?.code === "enrollment-results" &&
    section[3].section[0].section?.length) {
    usableSection = section[3].section[0].section;
    return <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Variable Name</Table.HeaderCell>
            {screenedGroupFound &&
              <Table.HeaderCell>Screened Group</Table.HeaderCell>}
            {enrolledGroupFound &&
              <Table.HeaderCell>Enrolled Group</Table.HeaderCell>}
            {groupAssignmentFound && <Table.HeaderCell>Comparative Evidence</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={"topline"}>
            <Table.Cell style={{ verticalAlign: "top" }}></Table.Cell>
            {screenedGroupFound &&
              <Table.Cell style={{ verticalAlign: "top" }}>
                {section[0].section[0].entry?.[0]?.display || section[0].section[0].entry?.[0]?.reference ||
                  "[Display value missing.]"}
              </Table.Cell>}
            {enrolledGroupFound &&
              <Table.Cell style={{ verticalAlign: "top" }}>
                {section[0].section[1].entry?.[0]?.display || section[0].section[1].entry?.[0]?.reference ||
                  "[Display value missing.]"}
              </Table.Cell>}
            {groupAssignmentFound &&
              <Table.Cell style={{ verticalAlign: "top" }}>
                {section[1].entry[0].display || section[1].entry[0].reference || "[Display value missing.]"}
              </Table.Cell>}
          </Table.Row>
          {usableSection.map((itemSection, index) => {
            let screenedSection = null;
            let enrolledSection = null;
            let comparativeSection = null;
            if (Array.isArray(itemSection.section)) {
              for (let subsection of itemSection.section) {
                let subsectionCode = subsection.code?.coding?.[0]?.code;
                if (subsectionCode === "evidence-for-screened-group") {
                  screenedSection = subsection;
                } else if (subsectionCode === "evidence-for-enrolled-group") {
                  enrolledSection = subsection;
                } else if (subsectionCode === "evidence-for-screened-vs-enrolled") {
                  comparativeSection = subsection;
                }
              }
              return <Table.Row key={index}>
                <Table.Cell style={{ verticalAlign: "top" }}>{itemSection.title || itemSection.focus?.display}</Table.Cell>
                {screenedGroupFound &&
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    {screenedSection && <span>
                      <DisplayFromFHIR xhtml={screenedSection.text?.div} />
                      <br />
                      <DisplayFromFHIR reference={screenedSection.entry?.[0]} />
                    </span>}
                  </Table.Cell>}
                {totalGroupFound &&
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    {enrolledSection && <span>
                      <DisplayFromFHIR xhtml={enrolledSection.text?.div} />
                      <br />
                      <DisplayFromFHIR reference={enrolledSection.entry?.[0]} />
                    </span>}
                  </Table.Cell>}
                {groupAssignmentFound &&
                  <Table.Cell style={{ verticalAlign: "top" }}>
                    {comparativeSection && <span>
                      <DisplayFromFHIR xhtml={comparativeSection.text?.div} />
                      <br />
                      <DisplayFromFHIR reference={comparativeSection.entry?.[0]} />
                    </span>}
                  </Table.Cell>}
              </Table.Row>
            } else {
              return <></>
            }
          })}
        </Table.Body>
      </Table>
    </>
  }
  return <></>
}

const CompositionMeasuresTableDisplay = ({ relatesTo, reportType }) => {
  if (Array.isArray(relatesTo)) {
    let rowArray = [];
    for (let item of relatesTo) {
      if (item.type === "depends-on" && item.label === reportType) {
        rowArray.push({ "name": item.display, "reference": item.resourceReference });
      }
    }
    return <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable Name</Table.HeaderCell>
          <Table.HeaderCell>EvidenceVariable Resource</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rowArray.map((row, rowIndex) => {
          return <Table.Row key={rowIndex}>
            <Table.Cell>{row.name}</Table.Cell>
            <Table.Cell><DisplayFromFHIR reference={row.reference} /></Table.Cell>
          </Table.Row>
        })}
      </Table.Body>
    </Table>
  } else {
    return <p>[Expected JSON not found.]</p>
  }
}

const CompositionTableReportViewerTool = ({ fhirJson, classificationsArrayState, classificationsLoadedState, reportType }) => {

  const history = useHistory();
  const globalContext = useContext(FevirContext);
  let description;
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-description') {
        description = extension.valueMarkdown;
      }
    }
  }
  let howToCite;
  let summaryOfFindingsStarter = {
    "derivedFrom": {
      "type": "derived-from",
      "label": "Derived from",
      "resourceReference": {
        "reference": "Composition/" + fhirJson.id,
        "type": "Composition",
        "display": fhirJson.title || fhirJson.name
      }
    },
    "outcomesRelatesTo": []
  };
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.resourceReference?.display;
      }
      if (relatedArtifactEntry.type === "depends-on") {
        if (relatedArtifactEntry.label === "Intervention Group") {
          summaryOfFindingsStarter.interventionGroup = relatedArtifactEntry;
        }
        if (relatedArtifactEntry.label === "Comparator Group") {
          summaryOfFindingsStarter.comparatorGroup = relatedArtifactEntry;
        }
        if (relatedArtifactEntry.label === "Outcome Measure") {
          summaryOfFindingsStarter.outcomesRelatesTo.push(relatedArtifactEntry);
        }
      }
    }
  }
  if (Array.isArray(fhirJson.subject)) {
    summaryOfFindingsStarter.subject = fhirJson.subject;
  }
  if (Array.isArray(fhirJson.section) && fhirJson.section.length > 0) {
    if (fhirJson.section[0].code?.coding?.[0]?.code === "groups" &&
      fhirJson.section[2]?.code?.coding?.[0]?.code === "results") {
      summaryOfFindingsStarter.outcomesSection = fhirJson.section[2].section || [];
      if (fhirJson.section[0].section?.[1]?.entry?.[0]) {
        summaryOfFindingsStarter.interventionGroup = {
          "type": "depends-on",
          "label": "Intervention Group",
          "resourceReference": fhirJson.section[0].section[1].entry[0]
        };
      }
      if (fhirJson.section[0].section?.[2]?.entry?.[0]) {
        summaryOfFindingsStarter.comparatorGroup = {
          "type": "depends-on",
          "label": "Comparator Group",
          "resourceReference": fhirJson.section[0].section[1].entry[0]
        };
      }
      if (fhirJson.section[1]?.entry?.length) {
        for (let entry of fhirJson.section[1].entry) {
          let relatedArtifactEntry = {
            "type": "depends-on",
            "label": "Outcome Measure",
            "resourceReference": entry
          };
          summaryOfFindingsStarter.outcomesRelatesTo.push(relatedArtifactEntry);
        }
      }
      summaryOfFindingsStarter.groupsSection = fhirJson.section[0];
      summaryOfFindingsStarter.variablesSection = fhirJson.section[1];
    } else if (fhirJson.section[0].code?.coding?.[0]?.code === "groups" &&
      fhirJson.section[3]?.code?.coding?.[0]?.code === "results") {
      summaryOfFindingsStarter.outcomesSection = fhirJson.section[3].section || [];
      if (fhirJson.section[0].section?.[3]?.entry?.[0]) {
        summaryOfFindingsStarter.interventionGroup = {
          "type": "depends-on",
          "label": "Intervention Group",
          "resourceReference": fhirJson.section[0].section[3].entry[0]
        };
      }
      if (fhirJson.section[0].section?.[4]?.entry?.[0]) {
        summaryOfFindingsStarter.comparatorGroup = {
          "type": "depends-on",
          "label": "Comparator Group",
          "resourceReference": fhirJson.section[0].section[4].entry[0]
        };
      }
      if (fhirJson.section[2]?.entry?.length) {
        for (let entry of fhirJson.section[2].entry) {
          let relatedArtifactEntry = {
            "type": "depends-on",
            "label": "Outcome Measure",
            "resourceReference": entry
          };
          summaryOfFindingsStarter.outcomesRelatesTo.push(relatedArtifactEntry);
        }
      }
      summaryOfFindingsStarter.groupsSection = fhirJson.section[0];
      summaryOfFindingsStarter.variablesSection = fhirJson.section[2];
    } else {
      summaryOfFindingsStarter.outcomesSection = fhirJson.section;
    }
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      {reportType === "Outcome Measure" && <>
        <Button className="formButton" style={{ color: "#000000" }}
          content={`Create a Summary of Findings Report`}
          onClick={async () => {
            let resourceFOI = await createSummaryOfFindingsReport(fhirJson.title, summaryOfFindingsStarter, globalContext);
            if (resourceFOI) {
              history.push("/");
              history.push(`/resources/Composition/${resourceFOI}`);
            }
          }} />
        <br />
      </>}
      {description &&
        <div><b>Report Description: </b><DisplayFromFHIR markdown={description} />
        </div>}
      <h3 id="groups">Groups</h3>
      {fhirJson?.section?.[0]?.code?.coding?.[0]?.code === "groups" ?
        <>
          <EvidenceReportPackageSectionDisplay topSection={fhirJson.section[0]} />
        </>
        :
        <>
          <div style={{ marginLeft: "24px" }}>
            {Array.isArray(fhirJson.relatesTo) && fhirJson.relatesTo.map((item, itemIndex) => {
              if (item.type === "depends-on") {
                if (item.label === "Intervention Group") {
                  return <div key={itemIndex} >
                    <b>Intervention Group: </b>
                    <DisplayFromFHIR reference={item.resourceReference} />
                  </div>
                } else if (item.label === "Comparator Group") {
                  return <div key={itemIndex} >
                    <b>Comparator Group: </b>
                    <DisplayFromFHIR reference={item.resourceReference} />
                  </div>
                } else if (item.label === "Total Group") {
                  return <div key={itemIndex} >
                    <b>Total Group: </b>
                    <DisplayFromFHIR reference={item.resourceReference} />
                  </div>
                } else {
                  return <div key={itemIndex} ></div>
                }
              } else {
                return <div key={itemIndex} ></div>
              }
            })}
          </div>
        </>}
      {fhirJson?.section?.[1]?.code?.coding?.[0]?.code === "GroupAssignment" &&
        <EvidenceReportPackageSectionDisplay topSection={fhirJson.section[1]} />
      }
      <br />
      {reportType === "Baseline Measure" && <h3 id="baseline-measures">Baseline Measures</h3>}
      {reportType === "Participant Flow" && <h3 id="participant-flow-measures">Participant Flow Measures</h3>}
      {reportType === "Outcome Measure" && <h3 id="outcome-measures">Outcome Measures</h3>}
      {!(reportType === "Outcome Measure" || reportType === "Participant Flow" || reportType === "Baseline Measure") &&
        <h3 id="unclassified-measures">Unclassified Measures</h3>}
      <div style={{ marginLeft: "24px" }}>
        {fhirJson?.section?.[2]?.code?.coding?.[0]?.code === "variables" ? <>
          <EvidenceReportPackageSectionDisplay topSection={fhirJson.section[2]} />
        </>
          :
          <>
            {fhirJson?.section?.[1]?.code?.coding?.[0]?.code === "variables" ? <>
              <EvidenceReportPackageSectionDisplay topSection={fhirJson.section[1]} />
            </>
              :
              <CompositionMeasuresTableDisplay relatesTo={fhirJson.relatesTo} reportType={reportType} />
            }
          </>
        }
      </div>
      {(fhirJson?.section?.[3]?.code?.coding?.[0]?.code === "results" &&
        fhirJson.section[3].section?.[0]?.code?.coding?.[0]?.code === "enrollment-results")
        ?
        <>
          <h3 id="evidence">Enrollment Results</h3>
          {fhirJson.section[3].section[0].section?.length
            ?
            <EvidenceTableDisplay2 section={fhirJson.section}
              resultsType="enrollment-results" />
            :
            <EvidenceReportPackageSectionDisplay topSection={fhirJson.section[3].section[0]} />
          }
          <h3 id="comparative-results">Comparative Results</h3>
          {fhirJson.section[3].section?.[1] ? <>
            {(fhirJson.section[3].section[1].code?.coding?.[0]?.code === "comparative-results" &&
              fhirJson.section[3].section[1].section?.length)
              ?
              <>
                <EvidenceTableDisplay2 section={fhirJson.section}
                  resultsType="comparative-results" />
              </>
              :
              <EvidenceReportPackageSectionDisplay topSection={fhirJson.section[3].section[1]} />
            }
          </>
            :
            <p>No Comparative Results section.</p>
          }
        </>
        :
        <>
          <h3 id="evidence">Evidence</h3>
          <div style={{ marginLeft: "24px" }}>
            {fhirJson?.section?.[3]?.code?.coding?.[0]?.code === "results" ? <>
              <EvidenceReportPackageSectionDisplay topSection={fhirJson.section[3]} />
            </>
              :
              <EvidenceTableDisplay relatesTo={fhirJson.relatesTo} section={fhirJson.section} reportType={reportType} />
            }
          </div>
        </>
      }
      <br />
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.category) && fhirJson.category.length > 0 && <>
          {fhirJson.category.map((category, categoryIndex) => {
            return <span key={categoryIndex} >
              <b>Category: </b>
              <DisplayFromFHIR codeableConcept={category} />
            </span>
          })}
          <br /> <br />
        </>}
        {Array.isArray(fhirJson.subject) && fhirJson.subject.length > 0 && <>
          {fhirJson.subject.map((subject, subjectIndex) => {
            return <span key={subjectIndex} >
              <b>Subject: </b>
              <DisplayFromFHIR reference={subject} />
            </span>
          })}
          <br /> <br />
        </>}
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default CompositionTableReportViewerTool;