import React, { useState, useContext } from 'react';
import { Modal, Button, TextArea } from 'semantic-ui-react';
import { RadioGroup, Radio, FormControl, FormControlLabel } from '@mui/material';
//import server_config from './ServerConfiguration';
import { useToasts } from 'react-toast-notifications';
import submitToFevirServer from './SubmitToFevirServer';
import FevirContext from './FevirContext';

//const ports = server_config["ports"];
//const server_address = server_config["address"];

const submitFeeback = async (formState, globalContext, feedBackState, setFeedBackState, setFeedBackModalOpenState, addToast) => {
  let userState = globalContext.userState;
  let feedbackjson = {
    "resourceType": "ArtifactAssessment",
    "artifactReference": {
      "display": formState.pageTitle
    },
    "content": []
  };
  if (feedBackState.learningCurve) {
    feedbackjson["content"].push({
        "type": {
          "text": "Easy/Hard to learn 5-item Likert rating"
        },
        "classifier": [
          {
            "text": feedBackState.learningCurve
          }
        ]
    });
  }
  if (feedBackState.easeOfUse) {
    feedbackjson["content"].push({
        "type": {
          "text": "Easy/Hard to use 5-item Likert rating"
        },
        "classifier": [
          {
            "text": feedBackState.easeOfUse
          }
        ]
    });
  }
  if (feedBackState.experienceUsingTool) {
    feedbackjson["content"].push({
        "type": {
          "text": "Good/Poor experience 5-item Likert rating"
        },
        "classifier": [
          {
            "text": feedBackState.experienceUsingTool
          }
        ]
    });
  }

  if (feedBackState.additionalComment) {
    let feedbackComment = {
      "type": {
        "text": "Do you have any additional comments?"
      },
      "summary": feedBackState.additionalComment,
      "author": [{ "display": "Anonymous" }]
    };
    if (userState?.name) {
      feedbackComment["author"][0]["display"] =  userState.name;
    }
    feedbackjson["content"].push(feedbackComment);
  }

  const body = {
    'functionid': "simpleusabilityfeedbacksubmit",
    'tool': "simpleusabilityfeedbackform",
    'feedbackOnTool': formState.pageTitle,
    'fhirEntry': JSON.stringify(feedbackjson, null, 2),
    'title': "",
    'status': "active",
    'idToken': ""
  };

  let response = await submitToFevirServer(globalContext, 10000, body, true, false);
  
  if (response?.success === false) {
    //changeMedlineEntryState(false, "loadingSubmission");
    alert("We were unable to submit your feedback.");
  } else {
    //alert("success");
    setFeedBackState({});
    setFeedBackModalOpenState(false);
    addToast('Feedback submitted. Thank you.', { appearance: 'success' });
    //close out of modal?
  }
}

const FeedbackModal = ({feedBackModalOpenState, setFeedBackModalOpenState, feedBackState, setFeedBackState, formState, addToast}) => {
  
  const globalContext = useContext(FevirContext);
  return (
    <Modal
      style={{width: "60%", padding: "16px", margin: "16px"}}
      dimmer={<Modal.Dimmer style={{backgroundColor: "#00000077"}} />}
      open={feedBackModalOpenState}
      centered={false}
      content={<div style={{whiteSpace: "pre-wrap"}}>
        <Button style={{padding: "6px", position: "absolute", right: "14px"}} className="formButton negative" content="✖"
          onClick={() => {
            setFeedBackModalOpenState(false);
          } }
        />
        <div style={{fontSize: "16px"}}>
          <h2 style={{paddingTop: "0px", marginTop: "0px"}}>The <u>{formState.pageTitle}</u> is:</h2>
          <br/>
          <div>
            {/*<span><b>Please rate your experience:</b>&nbsp;&nbsp;&nbsp;&nbsp;
              <Dropdown
                  name="yourExperience"
                  placeholder="Your experience"
                  className={"thinDropdown"}
                  closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                  style={{ width: "240px", paddingTop: "6px", paddingBottom: "6px" }}
                  options={[ { key: "Good", text: "Good", value: "Good" }, { key: "Bad", text: "Bad", value: "Bad" } ]}
                  value={feedBackState.yourExperience || ''}
                  onChange={(e, data) => { setFeedBackState(prevState  => { return { ...prevState, "yourExperience": data.value } });  } }
                />
            </span>
            <br/>*/}
            <span>
            <table className="noStyleTable"><tbody>
              <tr>
                <td style={{textAlign: "right", fontWeight: "bold"}}>Easy to Learn</td>
                <td>
                  <RadioGroup
                    aria-label="LearningCurve"
                    name="LearningCurve"
                    label="LearningCurve"
                    value={feedBackState.learningCurve}
                    onChange={(e) => { setFeedBackState(prevState  => { return { ...prevState, "learningCurve": e.target.value } }); }}
                    row={true}
                    >
                      <Radio value="1 Very Easy to Learn" />
                      <Radio value="2 Somewhat Easy to Learn" />
                      <Radio value="3 Neither Easy nor Hard to Learn" />
                      <Radio value="4 Somewhat Hard to Learn" />
                      <Radio value="5 Very Hard to Learn" />
                  </RadioGroup>
                </td>
                <td style={{textAlign: "left", fontWeight: "bold"}}>Hard to Learn</td>
              </tr>
              <tr>
                <td style={{textAlign: "right", fontWeight: "bold"}}>Easy to Use</td>
                <td>
                  <RadioGroup
                    aria-label="EaseOfUse"
                    name="EaseOfUse"
                    label="EaseOfUse"
                    value={feedBackState.easeOfUse}
                    onChange={(e) => { setFeedBackState(prevState  => { return { ...prevState, "easeOfUse": e.target.value } }); }}
                    row={true}
                    >
                    <Radio value="1 Very Easy to Use" />
                    <Radio value="2 Somewhat Easy to Use" />
                    <Radio value="3 Neither Easy nor Hard to Use" />
                    <Radio value="4 Somewhat Hard to Use" />
                    <Radio value="5 Very Hard to Use" />
                  </RadioGroup>
                </td>
                <td style={{textAlign: "left", fontWeight: "bold"}}>Hard to Use</td>
              </tr>
              <tr>
                <td style={{textAlign: "right", fontWeight: "bold"}}>A good experience</td>
                <td>

                  <RadioGroup
                    aria-label="ExperienceUsingTool"
                    name="ExperienceUsingTool"
                    label="ExperienceUsingTool"
                    value={feedBackState.goodBadLook}
                    onChange={(e) => { setFeedBackState(prevState  => { return { ...prevState, "experienceUsingTool": e.target.value } }); }}
                    row={true}
                    >
                      <Radio value="1 Very Good Experience" />
                      <Radio value="2 Somewhat Good Experience" />
                      <Radio value="3 Neither Good nor Poor Experience" />
                      <Radio value="4 Somewhat Poor Experience" />
                      <Radio value="5 Very Poor Experience" />
                  </RadioGroup>
                </td>
                <td style={{textAlign: "left", fontWeight: "bold"}}>A poor experience</td>
              </tr>
            </tbody></table>
            </span>
            <br/>
            <p><b>Do you have any additional comments?</b>
            <TextArea style={{ marginTop: "4px", width: "98%"}} rows={5} className="inputField" type='text' label={'Additional Comment'} size="small" variant='outlined' value={feedBackState.additionalComment} onChange={(e) => { setFeedBackState(prevState  => { return { ...prevState, "additionalComment": e.target.value } });  } } />
            </p>
            <br/>
            <br/>
          </div>
          <Button style={{color: "#000000"}} className="formButton" content="Submit Feedback" 
          onClick={() => {submitFeeback(formState, globalContext, feedBackState, setFeedBackState, setFeedBackModalOpenState, addToast);}} />
        </div>
      </div>}
    />
  )
}


const FeedbackButton = ( { formState } ) => {
  
  const [feedBackModalOpenState, setFeedBackModalOpenState] = useState(false);
  const [feedBackState, setFeedBackState] = useState({});
  const { addToast } = useToasts();

  return (
    <div>
      <FeedbackModal feedBackModalOpenState={feedBackModalOpenState} 
      setFeedBackModalOpenState={setFeedBackModalOpenState} feedBackState={feedBackState} setFeedBackState={setFeedBackState} 
      formState={formState} addToast={addToast} />
    <div style={{opacity: "0.6", zIndex: "10", position: "absolute", cursor: "pointer", marginTop: "3px", right: "36px"}}><div
      className={"unselectable"}
      style={{backgroundColor: "lavender", fontSize: "24px", padding: "4px 8px", border: "2px solid #000000", borderRadius: "5px"}}
      onClick={() => {setFeedBackModalOpenState(true);}}
    ><span style={{fontSize: "16px", fontWeight: "bold"}}>Feedback</span> 💬</div>
    </div></div>
  );
};

  export default FeedbackButton;