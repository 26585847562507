const server_configuration = {
  "localdevelopment": {
    //"address": "http://localhost",
    "address": "https://dev.fevir.net",   //Hopefully this doesn't break anything if it does, make it https://fevir.net" I changed it to dev.fevir.net so the JSON tree writer will work, but if other things break change the url that's sent for dev to the tree writer
    //"node_server_address": "https://dev.fevir.net/nodeserver/",
    "node_server_address": "http://localhost:3104/",
    "go_server_address": "http://localhost:3107/",
    "http://localhost:3001": "http://localhost:3001",
    "https://computablepublishing.com": "https://computablepublishing.com",
    //"node_server_address": "https://fevir.net:3104/",
    "ports": {
      //"master_server": 443
      "master_server": 3104
    }
  },
  "development": {
    "address": "https://dev.fevir.net",   //Hopefully this doesn't break anything if it does, make it https://fevir.net" I changed it to dev.fevir.net so the JSON tree writer will work, but if other things break change the url that's sent for dev to the tree writer
    "node_server_address": "https://dev.fevir.net/nodeserver/",
    "go_server_address": "https://dev.fevir.net/goserver/",
    "https://computablepublishing.com": "https://computablepublishing.com",
    //"node_server_address": "https://fevir.net:3104/",
    "ports": {
      //"master_server": 443
      "master_server": 3104
    }
  },
  "production": {
    "address": "https://fevir.net",
    "node_server_address": "https://fevir.net/nodeserver/",
    "go_server_address": "https://fevir.net/goserver/",
    "https://computablepublishing.com": "https://computablepublishing.com",
    //"node_server_address": "https://fevir.net:3004/",
    "ports": {
      "master_server": 3004
    }
  }
};
let server_config;
if (window.location.hostname == "fevir.net") {
  server_config = server_configuration["production"];
} else if (window.location.hostname == "dev.fevir.net") {
  server_config = server_configuration["development"];
} else {
  server_config = server_configuration["localdevelopment"];
}
export default server_config;